import React, { useEffect, useRef, useState } from "react";
import copyIcon from "../../../assets/images/Copy.svg";
import { toastify } from "../../../helpers/toast/toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { api } from "../../../helpers/apiHelper/requestHelper";
import { storeUser, updateUser } from "../../../redux/users/usersSlice";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { setLoading } from "../../../redux/loading/loadingSlice";
import edit_icon from "../../../assets/images/edit_icon.svg";
import tickIcon from "../../../assets/images/tick.png";
import crossIcon from "../../../assets/images/cross.png";
import MyButton from "../../../components/button/Button";
import addIcon from "../../../assets/images/addIcon.png";
import { Overlay, Popover, Button } from "react-bootstrap";

export default function LeaderExchange() {
    const api = useApi();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user?.user);
    const exchangeRef = useRef();
    const apiKeyRef = useRef();
    const phraseRef = useRef();
    const secretKeyRef = useRef();

    const [tab, setTab] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [credentials, setCredentials] = useState({
        apiKey: "",
        secretKey: "",
        secretPhrase: "",
        exchange: "",
    });
    const ref = useRef(null);
    const [showPopover, setShowPopover] = useState(false);
    const [target, setTarget] = useState(null);
    const [quickConnect, setQuickConnect] = useState(true);
    const [showConnectMore, setShowConnectMore] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        // if (
        //     !user?.onboarding?.connect &&
        //     user?.firstVisit &&
        //     !user?.binanceCredentials?.exchange &&
        //     !user?.bybitCredentials?.exchange
        // ) {
        //     setShow(true);
        // }
        if (!user?.firstVisit && user?.onboarding?.connect) {
            setQuickConnect(false);
        }
        if (
            user?.firstVisit &&
            (user?.binanceCredentials?.isConnected ||
                user?.bybitCredentials?.isConnected)
        ) {
            setShowConnectMore(true);
        }
        if (user?.binanceCredentials?.exchange === "Binance") {
            setCredentials({
                ...credentials,
                exchange: "Binance",
            });
            getUserCredentials("Binance");
        } else if (user?.bybitCredentials?.exchange === "ByBit") {
            setCredentials({
                ...credentials,
                exchange: "ByBit",
            });
            getUserCredentials("ByBit");
        }
    }, [user]);

    const getUserCredentials = async (event) => {
        let exchangeKey = "";
        if (event === "Binance") {
            exchangeKey = "binance";
        } else if (event === "ByBit") {
            exchangeKey = "bybit";
        }

        if (
            (event === "ByBit" && !user?.bybitCredentials?.apiKey) ||
            (event === "Binance" && !user?.binanceCredentials?.apiKey)
        ) {
            // No credentials available for the selected exchange, don't call the API
            setCredentials((prev) => ({
                ...prev,
                apiKey: "",
                secretKey: "",
                exchange: event,
            }));
        } else {
            // Credentials are available, update the exchange and call the API
            const updatedCredentials = {
                ...credentials,
                exchange: event,
            };
            setCredentials(updatedCredentials);

            dispatch(setLoading(true));
            const response = await api(
                "get",
                `binanceCredentials/index/${exchangeKey}`
            );
            dispatch(setLoading(false));

            if (response?.status) {
                let data = response?.data;
                if (data?.length === 3) {
                    setCredentials({
                        apiKey: data?.apiKey,
                        secretKey: data?.secretKey,
                        secretPhrase: data?.secretPhrase,
                        exchange: data?.exchange,
                    });
                } else {
                    setCredentials({
                        apiKey: data?.apiKey,
                        secretKey: data?.secretKey,
                        exchange: data?.exchange,
                    });
                }
            }
        }
    };

    const oauthBinance = async () => {
        if (
            user?.binanceCredentials?.apiKey &&
            user?.binanceCredentials?.secretKey
        ) {
            await submit();
        } else {
            const clientId = "39vZ9un1Fd";
            const redirect_uri = "https%3A%2F%2Fmy.copyyy.it%2Fsignin";
            const scope = "user:openId,create:apikey";
            const cUrl = `https://accounts.binance.com/en/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect_uri}&scope=${scope}`;
            window.location.href = cUrl;
        }
    };

    const oauth = async () => {
        dispatch(setLoading(true));
        const clientId = "3ccf41551815af4";
        console.log("client id", clientId);
        const cUrl = `https://www.bybit.com/oauth?client_id=${clientId}&response_type=code&scope=openapi&state=123abc`;
        window.location.href = cUrl;
    };

    const handleConnectMore = () => {
        if (user?.binanceCredentials?.isConnected) {
            setCredentials({
                exchange: "ByBit",
            });
            setShowConnectMore(false);
        } else if (user?.bybitCredentials?.isConnected) {
            setCredentials({
                exchange: "Binance",
            });
            setShowConnectMore(false);
        }
    };

    const done = () => {
        navigate("/leader/tradeActive");
    };

    const submit = async () => {
        if (!credentials?.exchange) missingPopup("Exchange");
        else if (!credentials?.apiKey) missingPopup("API Key");
        // else if (!credentials?.secretPhrase) missingPopup("Secret Phrase");
        else if (!credentials?.secretKey) missingPopup("Secret Key");
        else {
            console.log({ ...credentials });
            dispatch(setLoading(true));
            const response = await api("post", "binanceCredentials/create", {
                ...credentials,
                // isConnected: true,
            });

            console.log("leaderConnect", response);
            if (response?.status) {
                toastify("Connected to exchange successfully.", "success");
                if (user?.firstVisit) {
                    setShowConnectMore(true);
                }
                dispatch(updateUser(response?.data));
                if (
                    user?.firstVisit &&
                    response?.data?.onboarding?.connect &&
                    response?.data?.binanceCredentials?.isConnected &&
                    response?.data?.bybitCredentials?.isConnected
                ) {
                    navigate("/leader/tradeActive");
                }
            } else {
                toastify(response?.message);
            }
            dispatch(setLoading(false));
        }
    };

    const disconnect = async () => {
        dispatch(setLoading(true));

        const isConnected =
            credentials?.exchange === "Binance"
                ? user?.binanceCredentials?.isConnected
                : credentials?.exchange === "ByBit"
                    ? user?.bybitCredentials?.isConnected
                    : false;

        if (isConnected) {
            const credentialToUpdate =
                credentials.exchange === "Binance"
                    ? {
                        binanceCredentials: {
                            ...user?.binanceCredentials,
                            isConnected: false,
                            isConnectedSpot: false,
                            isConnectedUsdFuture: false,
                        },
                    }
                    : credentials.exchange === "ByBit"
                        ? {
                            bybitCredentials: {
                                ...user?.bybitCredentials,
                                isConnected: false,
                                isConnectedSpot: false,
                                isConnectedDerivatives: false,
                            },
                        }
                        : null;

            if (credentialToUpdate) {
                const response = await api(
                    "post",
                    "users/update",
                    credentialToUpdate
                );

                if (response?.status) {
                    toastify("Exchange disconnected successfully.", "success");
                    dispatch(updateUser(response?.data?.user));
                } else {
                    toastify(response?.message);
                }
            }
        } else {
            toastify("No credentials found to disconnect.", "error");
        }

        dispatch(setLoading(false));
    };

    const checkConnected = () => {
        if (
            (credentials?.exchange === "Binance" &&
                user?.binanceCredentials?.isConnected) ||
            (credentials?.exchange === "ByBit" &&
                user?.bybitCredentials?.isConnected)
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div
            className="row justify-content-center h-100 py-0 less_content add-heading-border"
            style={{ padding: "0px 36px" }}
        >
            {/* {show && (
                <OnboardingModal
                    noAction={() => {
                        setShow(false);
                    }}
                    step="Step 2/2"
                    heading="Connect your exchange"
                    description="Now, connect your 
          exchange to get started."
                />
            )} */}
            <div className="col-lg-10 disable_animation_accordion d-flex flex-column">
                {user?.firstVisit && (
                    <h4 className="community_name mb-4">
                        {user?.leaderCommunity?.name}
                    </h4>
                )}
                <div className="page_title d-sm-flex align-items-sm-center justify-content-sm-between">
                    <h5 className="fs25 text_GB">Your exchange connection</h5>
                </div>
                {user?.firstVisit && (
                    <p className="mt-1 fs16">
                        You need to connect to the exchange you’re trading from
                        to enable our copy trading service.
                    </p>
                )}

                <div className="h-100 d-flex flex-column">
                    <div className="page_form d-grid gap-4 mt-sm-4 pt-sm-3 pb-sm-5">
                        {/* <div className="row d-sm-flex d-grid gap-sm-0 gap-4"> */}
                        <div className="col-12">
                            <label className="fs16 text_dark5 mb-1">
                                Select your exchange
                            </label>
                            <select
                                className="w-100 form-control"
                                value={credentials?.exchange}
                                onChange={(e) => {
                                    setCredentials((prev) => ({
                                        ...prev,
                                        exchange: e.target.value,
                                    }));
                                    getUserCredentials(e.target.value);
                                }}
                            >
                                <option value="" disabled>
                                    Select exchange
                                </option>
                                <option value="Binance">
                                    Binance{" "}
                                    {user?.binanceCredentials?.isConnected && (
                                        <span>(Connected)</span>
                                    )}
                                </option>

                                <option value="ByBit">
                                    ByBit{" "}
                                    {user?.bybitCredentials?.isConnected && (
                                        <span>(Connected)</span>
                                    )}
                                </option>
                            </select>
                        </div>
                        {/* </div> */}

                        {(credentials?.exchange === "Binance" &&
                            !quickConnect &&
                            !user?.binanceCredentials?.isConnected) ||
                            (credentials?.exchange === "ByBit" &&
                                !quickConnect &&
                                !user?.bybitCredentials?.isConnected) ? (
                            <div className="col-12 mt-auto">
                                <label className="fs16 text_dark5 mb-1">
                                    API Key
                                </label>
                                <input
                                    type="password"
                                    className="form-control fs15"
                                    placeholder="Api key"
                                    value={credentials?.apiKey}
                                    onChange={(e) =>
                                        setCredentials((prev) => ({
                                            ...prev,
                                            apiKey: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                        ) : null}
                        {(credentials?.exchange === "Binance" &&
                            !quickConnect &&
                            !user?.binanceCredentials?.isConnected) ||
                            (credentials?.exchange === "ByBit" &&
                                !quickConnect &&
                                !user?.bybitCredentials?.isConnected) ? (
                            // <div className="row d-sm-flex d-grid gap-sm-0 gap-4">
                            <div className="col-12">
                                <label className="fs16 text_dark5 mb-1">
                                    Secret Key
                                </label>
                                <input
                                    type="password"
                                    className="form-control fs15"
                                    placeholder="Secret key"
                                    value={credentials?.secretKey}
                                    onChange={(e) =>
                                        setCredentials((prev) => ({
                                            ...prev,
                                            secretKey: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                        ) : // </div>
                            null}

                        {(credentials?.exchange === "Binance" &&
                            !quickConnect &&
                            !user?.binanceCredentials?.isConnected) ||
                            (credentials?.exchange === "ByBit" &&
                                !quickConnect &&
                                !user?.bybitCredentials?.isConnected) ? (
                            <>
                                <div className="my-3 d-flex row">
                                    <div className="page_title">
                                        <h4 className="fs16 bold">
                                            IP address (if needed)
                                        </h4>
                                    </div>
                                    <p className="fs16">
                                        Some exchanges require a specific IP
                                        address to create the API.
                                    </p>
                                    <div className="mt-2 col-12 position-relative d-flex align-items-center justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control fs15 bold"
                                            value="52.214.92.9"
                                            disabled
                                        />
                                        <span className="position-absolute me-2">
                                            <img
                                                className="cursor-pointer me-2"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        "52.214.92.9"
                                                    );
                                                    toastify(
                                                        "IP Address copied successfully!",
                                                        "success"
                                                    );
                                                }}
                                                src={copyIcon}
                                            />
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <div className="page_title">
                                            <h4 className="fs16 bold">
                                                API permission status
                                            </h4>
                                        </div>
                                        <p className="fs16">
                                            This is what your exchange allow us
                                            to trade on your behalf .
                                        </p>
                                    </div>

                                    <div className="my-3">
                                        <div className="d-flex align-items-center mb-2">
                                            <img
                                                src={
                                                    credentials?.exchange ===
                                                        "Binance"
                                                        ? user
                                                            ?.binanceCredentials
                                                            ?.isConnectedSpot
                                                            ? tickIcon
                                                            : crossIcon
                                                        : credentials?.exchange ===
                                                            "ByBit" &&
                                                            user?.bybitCredentials
                                                                ?.isConnectedSpot
                                                            ? tickIcon
                                                            : crossIcon
                                                }
                                            />

                                            <label
                                                for="1"
                                                className="permission_options ms-2"
                                            >
                                                {credentials?.exchange ===
                                                    "Binance"
                                                    ? "Binance Spot"
                                                    : credentials?.exchange ===
                                                        "ByBit"
                                                        ? "ByBit Spot"
                                                        : null}
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center mb-2">
                                            <img
                                                src={
                                                    credentials?.exchange ===
                                                        "ByBit"
                                                        ? user?.bybitCredentials
                                                            ?.isConnectedDerivatives
                                                            ? tickIcon
                                                            : crossIcon
                                                        : credentials?.exchange ===
                                                            "Binance" &&
                                                            user
                                                                ?.binanceCredentials
                                                                ?.isConnectedUsdFuture
                                                            ? tickIcon
                                                            : crossIcon
                                                }
                                            />

                                            <label
                                                for="2"
                                                className="permission_options ms-2"
                                            >
                                                {credentials?.exchange ===
                                                    "Binance"
                                                    ? "Binance Future"
                                                    : credentials?.exchange ===
                                                        "ByBit"
                                                        ? "ByBit Derivatives"
                                                        : null}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className="row justify-content-center align-items-center mt-4">
                        <div className="mt-2">
                            {user?.firstVisit &&
                                (user?.binanceCredentials?.isConnected ||
                                    user?.bybitCredentials?.isConnected) &&
                                !(
                                    user?.binanceCredentials?.isConnected &&
                                    user?.bybitCredentials?.isConnected
                                ) &&
                                showConnectMore ? (
                                <MyButton
                                    name="CONNECT MORE"
                                    newStyle="btn bg-dark radius h_60 w_260 fs20 custom-btn mb-3 white-color"
                                    exchange={true}
                                    handleClick={handleConnectMore}
                                />
                            ) : null}

                            {user?.firstVisit && showConnectMore && (
                                <MyButton name="DONE" handleClick={done} />
                            )}

                            {!showConnectMore &&
                                (credentials?.exchange === "Binance" ||
                                    credentials?.exchange === "ByBit") ? (
                                <MyButton
                                    exchange={true}
                                    newStyle={`${checkConnected()
                                        ? "btn-danger"
                                        : "btn-success"
                                        } btn radius h_60 w_260 fs20 custom-btn`}
                                    name={
                                        checkConnected()
                                            ? "DISCONNECT"
                                            : "CONNECT"
                                    }
                                    handleClick={
                                        checkConnected()
                                            ? disconnect
                                            : credentials?.exchange ===
                                                "ByBit" && quickConnect
                                                ? oauth
                                                : credentials?.exchange ===
                                                    "Binance" && quickConnect
                                                    ? oauthBinance
                                                    : submit
                                    }
                                />
                            ) : null}
                            {(credentials?.exchange === "ByBit" ||
                                credentials?.exchange === "Binance") && (
                                    <div className="mt-3 text-center fs16">
                                        {quickConnect ? (
                                            <a
                                                className="cyan-color"
                                                onClick={() =>
                                                    setQuickConnect(false)
                                                }
                                            >
                                                Connect using API Keys instead
                                            </a>
                                        ) : (
                                            <a
                                                className="cyan-color"
                                                onClick={() => {
                                                    setQuickConnect(true);
                                                }}
                                            >
                                                Use quick connect instead
                                            </a>
                                        )}
                                    </div>
                                )}
                        </div>
                        <div className="my-5">
                            <h4 className="fs15">
                                Any problems with setting up connecting to your
                                exchange please have a look at this{" "}
                                <a
                                    className="text-dark bold text-underline"
                                    href="https://copyyy.it/howto"
                                >
                                    'how to'
                                </a>{" "}
                                and if you're having issues{" "}
                                <a
                                    className="text-dark bold text-underline"
                                    href="mailto:support@copyyy.it"
                                >
                                    contact support
                                </a>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
