import React, { useEffect, useRef, useState } from "react";
import edit_icon from "../../../assets/images/Pen.svg";
import open_icon from "../../../assets/images/View.svg";
import close_icon from "../../../assets/images/noView.svg";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { storeUser, updateUser } from "../../../redux/users/usersSlice";
import { toastify } from "../../../helpers/toast/toastify";
import { setLoading } from "../../../redux/loading/loadingSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import SettingsSelect from "./SettingsSelect";
import CloseCopyTrade from "../../../components/modals/CloseCopyTrade";
import RenderLinks from "../../../components/links/RenderLinks";
import { useNavigate } from "react-router-dom";
export default function LeaderAccountSettings() {
    const api = useApi();
    const [data, setData] = useState({
        name: "",
        email: "",
        password: "",
        passwordEdit: false,
    });
    const dispatch = useDispatch();
    const [show, setShow] = useState(null);
    const [security, setSecurity] = useState({});
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editable, setEditable] = useState({
        name: true,
        email: true,
        password: true
    })

    const user = useSelector((state) => state?.user?.user);
    const navigate = useNavigate();


    useEffect(() => {
        setSecurity(user?.security);
    }, [user]);

    console.log(security, "==>security??")
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const fetchPassword = async () => {
        if (user) {
            const response = await api("get", "users/viewPassword");
            // setData({ ...data, password: response?.data });
            setData(prevData => ({
                ...prevData,
                password: response.data
            }));
        }
    };

    console.log(data, "dataside")

    const [businesName, setBusinessName] = useState("")

    useEffect(() => {
        if (user) {
            setData({
                ...data,
                name: user?.leaderCommunity?.name,
                email: user?.email,
                password: user?.password,
            });
            fetchPassword();
            setBusinessName(data?.name)
        }
    }, [user]);

    console.log(businesName, "businessName")

    const save = async (changeOtpSettings) => {
        setShow(null);
        dispatch(setLoading(true));
        let changes = {};
        const emailRegex = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
        if (!emailRegex.test(data?.email)) {
            hanldeEditableInput(emailRef)
            toastify("Please enter a valid email address");
            dispatch(setLoading(false));
            return;
        }

        if (data?.name !== user?.leaderCommunity?.name)
            changes = {
                ...changes,
                name: data?.name,
                communityId: user?.leaderCommunity?._id,
            };
        if (user?.email !== data?.email)
            changes = { ...changes, email: data?.email };
        if (data?.password) changes = { ...changes, password: data?.password };
        if (security)
            changes = {
                ...changes,
                security: {
                    ...security,
                    otp: changeOtpSettings ? security.otp : true,
                },
            };

        console.log(changeOtpSettings, "==securityInside")

        if (data?.email || data?.name || data?.password || security) {
            const response = await api("post", "users/updateAccountSettings", {
                ...changes,
            });
            if (response?.status) {
                toastify(
                    "Account Information updated successfully.",
                    "success"
                );
                if (changes?.email) {
                    localStorage.setItem(
                        "lastVisitedRoute",
                        window.location.pathname
                    );
                    localStorage.removeItem("token");
                    dispatch(
                        storeUser({
                            user: null,
                            token: null,
                        })
                    );
                }
                if (changes?.name) {
                    let _user = { ...user };
                    _user["leaderCommunity"] = response?.data?.community;
                }
                if (changes?.password) {
                    setData({ ...data, passwordEdit: false });
                }
                dispatch(storeUser(response?.data));
                localStorage.setItem("token", response?.data?.token);
            } else {
                toastify(response?.message);
                console.log("userss", user)
                setData({
                    ...data,
                    email: user?.email,
                })
            }
        }
        console.log(data?.email, "dataemail")
        dispatch(setLoading(false));
        setEditable({
            name: true,
            email: true,
            password: true
        })
        // setData({
        //     name: "",
        //     email: "",
        //     password: "",
        //     passwordEdit: false,
        // })
    };

    const noAction = () => {
        setShowModal(false);
    };

    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("lastVisitedRoute");
        // localStorage.clear()
        dispatch(
            storeUser({
                user: null,
                token: null,
            })
        );

        navigate("/login");
    };

    const handleCloseService = async () => {
        dispatch(setLoading(true));
        const response = await api("get", "users/terminateCommunity");
        dispatch(setLoading(false));
        setShowModal(false);
        if (response?.status) {
            logOut();
        } else {
            toastify(response?.message);
        }
    };


    const hanldeEditableInput = (inputValue) => {
        let userAgent = navigator?.userAgent;
        let tempInput = null
        if (!userAgent?.includes("Firefox")) {
            // console.log("fireBox-Browser");
            tempInput = document.createElement('input');
            document.body.appendChild(tempInput);
            tempInput.className = "hidden-input"
            tempInput.focus();
        }

        setTimeout(() => {
            if (tempInput) {
                tempInput?.remove();
            }
            if (inputValue.current) {
                inputValue?.current?.focus();
            }
        }, 0);
    }

    console.log(editable?.name, "nameeditable")
    return (
        <div className="container">
            <div className="row justify-content-center h-100 support_wrapper">
                {show ? (
                    <ConfirmationModal
                        yesAction={() => save(true)}
                        noAction={() => setShow(null)}
                        heading="If you change your business"
                        subheading="name it will affect your service"
                        description="If you change your business name your landing page URL will change too!"
                    />
                ) : show == false ?
                    (<ConfirmationModal
                        yesAction={() => save(true)}
                        noAction={() => setShow(null)}
                        heading="Updating your account information may affect how you access our services."
                        subheading=""
                        description="Please review your changes carefully to ensure accuracy."
                    />)
                    : ""

                }
                {showModal && (
                    <CloseCopyTrade
                        noAction={noAction}
                        yesAction={handleCloseService}
                    />
                )}

                <div className="copyTrade pb-sm-50px">
                    {/* <span className="topLines"></span> */}
                    <div className="connection p-top p-top-on-mobile">
                        <div className="page_title d-sm-flex align-items-sm-center justify-content-sm-between">
                            <h4 className="fs20 text_GB mb-51">
                                Your settings
                            </h4>
                        </div>
                        <div className="h-100 d-flex flex-column position-relative">
                            <div className="mx-562">
                                <div className="row">
                                    <div className=" pb-4">
                                        <div className="customInput">
                                            <label
                                                onClick={() => {
                                                    setEditable((prevEditable) => ({
                                                        ...prevEditable,
                                                        name: !prevEditable.name
                                                    }));
                                                    hanldeEditableInput(nameRef)
                                                    // Use setTimeout to ensure that the input is enabled before focusing
                                                    // setTimeout(() => {
                                                    //     nameRef.current.focus();
                                                    // }, 0);
                                                }}
                                                className="cursor-pointer editIcon"
                                            >
                                                <img
                                                    src={edit_icon}
                                                    alt="img"
                                                />
                                            </label>
                                            <div className="form-floating">
                                                <input
                                                    id="nameReff"
                                                    ref={nameRef}
                                                    type="text"
                                                    className="form-control fs15"
                                                    placeholder="Business name (customers see this)"
                                                    disabled={editable?.name}
                                                    value={data?.name}
                                                    onChange={(e) =>
                                                        setData({
                                                            ...data,
                                                            name: e.target
                                                                .value,
                                                        })
                                                    }
                                                />
                                                <label for="nameReff">
                                                    Business name (what your
                                                    subs see)
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pb-4 mt-auto">
                                        <div className="customInput">
                                            <label
                                                onClick={() => {
                                                    setEditable((prevEditable) => ({
                                                        ...prevEditable,
                                                        email: !prevEditable.email
                                                    }));
                                                    hanldeEditableInput(emailRef)
                                                    // Use setTimeout to ensure that the input is enabled before focusing
                                                    // setTimeout(() => {
                                                    //     emailRef.current.focus();
                                                    // }, 0);
                                                }}
                                                className="cursor-pointer editIcon"
                                            >
                                                <img
                                                    src={edit_icon}
                                                    alt="img"
                                                />
                                            </label>
                                            <div className="form-floating">
                                                <input
                                                    ref={emailRef}
                                                    type="email"
                                                    id="emailReff"
                                                    className="form-control fs15"
                                                    placeholder="Email Address"
                                                    value={data?.email}
                                                    disabled={editable?.email}
                                                    onChange={(e) =>
                                                        setData({
                                                            ...data,
                                                            email: e.target
                                                                .value,
                                                        })
                                                    }
                                                />
                                                <label for="emailReff">
                                                    Email
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pb-4">
                                        <div className="customInput">
                                            <label

                                                onClick={() => {
                                                    setEditable((prevEditable) => ({
                                                        ...prevEditable,
                                                        password: !prevEditable.password
                                                    }));
                                                    setData({
                                                        ...data,
                                                        passwordEdit:
                                                            !data.passwordEdit,
                                                    });
                                                    hanldeEditableInput(passwordRef)

                                                    // setTimeout(() => {
                                                    //     passwordRef.current.focus();
                                                    // }, 0)
                                                }}
                                                className="cursor-pointer editIcon"
                                            >
                                                <img
                                                    src={edit_icon}
                                                    alt="img"
                                                />
                                            </label>
                                            <div className="form-floating">
                                                <input
                                                    ref={passwordRef}
                                                    type={
                                                        showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    id="password"
                                                    className="form-control fs15"
                                                    placeholder={
                                                        data?.passwordEdit
                                                            ? "Enter New Password"
                                                            : "**********"
                                                    }
                                                    disabled={editable?.password}
                                                    value={data?.password}
                                                    onChange={(e) =>
                                                        setData({
                                                            ...data,
                                                            password:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                                <label for="password">
                                                    Password
                                                </label>
                                            </div>
                                            <div
                                                className="view-icon"
                                                onClick={() => {
                                                    togglePasswordVisibility();
                                                    // fetchPassword();
                                                }}
                                            >
                                                {showPassword ? (
                                                    <img
                                                        src={open_icon}
                                                        alt="close Icon"
                                                    />
                                                ) : (
                                                    <img
                                                        src={close_icon}
                                                        alt="open Icon"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="setting-page-mt-80">
                                        <div className="securitytitle">
                                            <h3>Security on your account</h3>
                                        </div>
                                    </div>
                                    <div className="setting-select-box pb-4">
                                        <div className="settingDropdown">
                                            <p className="descriptive">
                                                Use One Time Password password
                                                on log-in?
                                            </p>
                                            <SettingsSelect
                                                title="Use One Time Password password on log-in?"
                                                data={security?.otp}
                                                setData={(value) =>
                                                    setSecurity({
                                                        logout: security?.logout,
                                                        otp: value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="pb-4">
                                        <div className="settingDropdown">
                                            <p className="descriptive">
                                                Log out after 15 min. of
                                                inactivity?
                                            </p>
                                            <SettingsSelect
                                                title="Log out after 15 min. of inactivity?"
                                                data={security?.logout}
                                                setData={(value) =>
                                                    setSecurity({
                                                        otp: security?.otp,
                                                        logout: value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="mt-2 mb-5 d-flex justify-content-end">
                                            <button
                                                className="cyan-btn promoteBtn medium"
                                                onClick={() => {
                                                    if (
                                                        user?.name !==
                                                        data?.name
                                                    ) {
                                                        setShow(data?.name !== businesName);
                                                    } else {
                                                        save();
                                                    }
                                                }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="articleRelatives">
                        <RenderLinks page="settings" />
                    </div>
                </div>
            </div>
        </div >
    );
}
